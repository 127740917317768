import React from 'react'

const Login = () => {
  return (
    <div>
        <h1>
            Login
        </h1>
    </div>
  )
}

export default Login